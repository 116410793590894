import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

const CoreApolloProvider = dynamic(() =>
  import('@apollo/client').then((mod) => mod.ApolloProvider)
);

const APOLLO_ROUTES = ['/admin'];

export default function ApolloProvider({ children }) {
  const [client, setClient] = useState(null);
  const { route } = useRouter();

  const active = useMemo(
    () => APOLLO_ROUTES.some((r) => route.startsWith(r)),
    [route]
  );

  useEffect(() => {
    if (!active || client !== null) {
      return;
    }

    (async () => {
      const { HttpLink, ApolloClient, InMemoryCache, concat } = await import(
        '@apollo/client'
      );
      const { setContext } = await import('@apollo/client/link/context');
      const httpLink = new HttpLink({ uri: '/api/graphql' });

      const authMiddleware = setContext(async () => {
        const Auth = (await import('@aws-amplify/auth')).default;
        const credentials = await Auth.currentSession();
        const token = credentials?.getAccessToken()?.getJwtToken();

        return {
          headers: {
            authorization: token,
          },
        };
      });

      const newClient = new ApolloClient({
        link: concat(authMiddleware, httpLink),
        cache: new InMemoryCache(),
        defaultOptions: {
          watchQuery: {
            partialRefetch: true,
          },
        },
      });

      setClient(newClient);
    })();
  }, [active, client]);

  if (!active) {
    return children;
  }

  if (!client) {
    return null;
  }

  return <CoreApolloProvider client={client}>{children}</CoreApolloProvider>;
}
