import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './i18n/en.json';
import fr from './i18n/fr.json';

i18n.use(initReactI18next).init({
  resources: {
    en,
    fr,
  },
  lng: 'en',
  fallbackLng: process.env.NODE_ENV === 'development' ? 'dev' : 'en',
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
