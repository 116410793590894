import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import dynamic from 'next/dynamic';

import '../i18n';
import Favicon from 'components/favicon';
import SessionProvider from 'components/sessionprovider';
import ApolloProvider from 'components/apolloprovider';

// Do not load Amplitude on SSR as its lib requires a window object
const Amplitude = dynamic(() => import('components/amplitude'), { ssr: false });

// err => Workaround for https://github.com/vercel/next.js/issues/8592
export default function App({ Component, pageProps, err }) {
  const { locale } = useRouter();
  const { i18n } = useTranslation();
  if (i18n.language !== locale) {
    i18n.changeLanguage(locale);
  }

  return (
    <>
      <Favicon />
      <SessionProvider>
        <ApolloProvider>
          <Amplitude />
          <Component {...pageProps} err={err} />
        </ApolloProvider>
      </SessionProvider>
    </>
  );
}
