import { createContext, useState } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';

const SessionLoader = dynamic(() => import('./sessionloader'));

export const SessionContext = createContext(null);

function SessionProvider({ children }) {
  const router = useRouter();
  const [user, setUser] = useState(false);

  // For admin routes, we need a user before the route is loaded
  const requireUser = router.route.startsWith('/admin');

  // If user is required and we have confirmed that there is none, got to login
  if (requireUser && user === null) {
    router.push(`/login?from=${encodeURIComponent(router.route)}`);
    return null;
  }

  // We don't need to retrieve user details on the landing page
  const loadUser = router.route !== '/';

  // Only load children if we do not need a user or we have one loaded
  const loadChildren = !requireUser || user;

  return (
    <SessionContext.Provider value={{ user }}>
      {loadUser && <SessionLoader user={user} setUser={setUser} />}
      {loadChildren && children}
    </SessionContext.Provider>
  );
}

export default SessionProvider;
